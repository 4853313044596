.card {
    height: 30vh;
    width: 60vw;
    box-shadow: 0px 0px 50px -8px rgba(0,0,0,0.3);
    border-radius: 1rem;
    display: flex;
    margin-bottom: 5rem;
    color: #000;
}

.card_ldiv {
    height: 100%;
    width: 30%;
    background-size: cover;
    background-position: center;
    border-radius: 1rem 0 0 1rem;
}

.card_rdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 3vh 2vh 2rem;
}

.card_header {
    font-size: 6vh;
}

.card_txt {
    font-size: 2.5vh;
}

@media screen and (max-width: 1024px) {
    .card {
        width: 85vw;
        box-shadow: 0px 0px 50px -8px rgba(0,0,0,0.3);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 7.5vw;
        color: #000;
    }
    
    .card_ldiv {
        height: 50%;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 1rem 1rem 0 0;
    }
    
    .card_rdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50%;
        padding: 1.5rem;
    }
    
    .card_header {
        font-size: 3.5vh;
    }
    
    .card_txt {
        font-size: 2vh;
    }
}