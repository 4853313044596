@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

.contact_main_section {
    background: linear-gradient(0deg, rgba(212,174,231,0.3) 0%, rgba(255,255,255,1) 50%, rgba(212,174,231,0.3) 100%);
    min-height: 100vh;
    padding-top: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contact_ldiv {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    gap: 1.5rem;
    width: 45%;
}

.contact_header {
    font-size: 6vh;
}

.contact_input {
    width: 100%;
    font-family: 'Oswald', sans-serif;
    border: #000 solid 0.1em;
    background-color: #fff;
}

.contact_txt_area {
    width: 100%;
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: #000 solid 0.1em;
    background-color: #fff;
}

.contact_btn {
    height: 3.5rem;
    font-size: 1.5rem;
    color: #fff;
    background-color: #5F2F76;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Oswald', sans-serif;
}

.contact_vline {
    height: 70vh;
    width: 0.2rem;
    background-color: #000;
    border-radius: 1000px;
}

.contact_logos {
    height: 3rem;
    margin: 0 0.4rem;
}

.contact_rdiv {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    height: 80vh;
    justify-content: center;
    align-items: center;
}

.contact_details_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_img1 {
    width: 4rem;
}

.contact_img2 {
    width: 4rem;
    margin-top: 4rem;
}

.contact_img3 {
    width: 4rem;
    margin-top: 4rem;
}

.contact_txt {
    font-size: 1.5rem;
}

@media screen and (max-width: 1024px) {
    .contact_main_section {
        min-height: fit-content;
        padding-top: 3.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .contact_ldiv {
        width: 90%;
        height: fit-content;
        padding: 3rem 0;
    }
    
    .contact_vline {
        height: 0;
        width: 0;
        background-color: transparent;
    }
    
    .contact_rdiv {
        width: 90%;
        height: fit-content;
        padding: 0 0 3rem 0;
    }
}