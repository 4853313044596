.error_div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error_text {
    font-size: 4vw;
}

@media screen and (max-width: 1024px) {
    .error_text {
        font-size: 10vw;
    }
}