@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

.main_section {
    min-height: 100vh;
    width: 100vw;
    padding-top: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Oswald', sans-serif;
}

.add_btn {
    width: 60vw;
    margin: 5rem 0;
    height: fit-content;
    padding: 1rem 0;
    font-size: 3vh;
    color: #fff;
    background-color: #5F2F76;
    border: none;
    border-radius: 1rem;
    font-family: 'Oswald', sans-serif;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .main_section {
        min-height: 100vh;
        width: 100vw;
        padding-top: 3.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Oswald', sans-serif;
    }
    
    .add_btn {
        width: 85vw;
        margin: 7.5vw 0;
        height: fit-content;
        padding: 0.5rem 0;
        font-size: 3vh;
        color: #fff;
        background-color: #5F2F76;
        border: none;
        border-radius: 0.4rem;
        font-family: 'Oswald', sans-serif;
        cursor: pointer;
    }
}