.single_restaurant_main_section {
    height: fit-content;
    padding: 3.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.single_restaurant_hero_section {
    height: 40vh;
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-position: center;
    color: #fff;
    padding: 0 0.5rem;
}

.single_restaurant_hero_bg {
    z-index: -100;
    object-fit: fill;
}

.single_restaurant_hero_bg_layer {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    margin-top: 3.5rem;
    z-index: 0;
}

.single_r_header {
    z-index: 1;
    font-size: 10vh;
}

.single_r_description {
    z-index: 1;
    font-size: 3vh;
}

.single_restaurant_menu_section {
    display: flex;
    height: fit-content;
    padding: 2rem 0;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 60%;
    max-width: 1000px;
}

.single_restaurant_menu_heading {
    font-size: 6vh;
}

.single_restaurant_menu_hline {
    width: 100%;
    height: 0.1rem;
    background-color: #000;
    margin: 1rem 0;
}

.single_restaurant_img {
    height: 1.3rem;
}

.single_restaurant_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -0.7rem;
}

.single_restaurant_footer_child {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

@media screen and (max-width: 1024px) {
    .single_r_header {
        font-size: 6vh;
    }
    .single_r_description {
        font-size: 3vh;
        text-align: center;
    }
}