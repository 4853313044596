@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

.add_restaurant_main_section {
    padding-top: 10rem;
    height: fit-content;
    min-height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form {
    width: 60%;
    max-width: 1000px;
}

.form_header {
    font-size: 5rem;
    margin-bottom: 5vh;
}

.form_input_div {
    margin-bottom: 1rem;
}

.form_menu_section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form_input_div_menu {
    display: flex;
    gap: 1rem;
}

.add_menu_btn {
    width: 100%;
    font-size: 2rem;
    padding: 0;
    font-weight: 100;
    margin-top: 1rem;
    color: #777;
    background-color: #fff;
    border: 2px dashed #ccc;
    border-radius: 5px;
    font-family: 'Oswald', sans-serif;
    cursor: pointer;
}

.delete_menu_btn {
    width: 8rem;
    font-size: 2rem;
    padding: 0;
    font-weight: 100;
    color: #777;
    background-color: #fff;
    border: 2px dashed #ccc;
    border-radius: 5px;
    font-family: 'Oswald', sans-serif;
    cursor: pointer;
}

.form_submit_btn {
    width: 100%;
    margin: 2rem 0 4rem 0;
    height: 3.5rem;
    font-size: 1.5rem;
    color: #fff;
    background-color: #5F2F76;
    border: none;
    border-radius: 0.4rem;
}

@media screen and (max-width: 1024px) {
    .form_header {
        font-size: 9vw;
    }
    .form {
        width: 90%;
    }
}